import React, {useEffect, useRef} from 'react'
import Boton from "./Boton";
import icoDocumentos from './img/ico-documentos.png'
import icoUsuario from './img/ico-usuario.png'
import icoControl from './img/ico-control.png'
import icoPrevisibilidad from './img/ico-previsibilidad.png'
import icoEficiencia from './img/ico-eficiencia.png'
import icoSustentabilidad from './img/ico-sustentabilidad.png'
import icoNegocio from './img/ico-negocio.png'
import icoSistema from './img/ico-sistema.png'
import icoUsuarios from './img/ico-usuarios.png'
import icoArbol from './img/ico-arbol.png'
import TarjetaPlanes from "./TarjetaPlanes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppSelector} from "./hooks";

const VideoIngles = React.lazy(() => import('./video/VideoIngles'))
const VideoEspaniol = React.lazy(() => import('./video/VideoEspaniol'))

const Inicio = () => {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const idioma = useAppSelector(state => state.idioma.idioma)

    const iconosGrandes = '18vw'
    const iconosChicos = '8vw'
    const iconosMini = '4vw'

    const enlaceMail = 'mailto:hello@bcontracts.com?subject=' +
        encodeURIComponent('Quiero más información sobre bcontracts')

    const horas = Math.floor(Math.abs(new Date() - new Date('2017-01-01')) / 36e5)
    const fmt = new Intl.NumberFormat()

    const planesRef = useRef()

    useEffect(() => {
        switch (params.get('q')) {
            case 'plans':
                planesRef.current?.scrollIntoView()
                break;
            default:
        }
    }, [params])

    return (
        <>
            {/*<div className='container-fluid fondoGradientInicio py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 pt-5'>
                            <h3 className='text-light mb-5'>
                                Aca podria meter el video de presentacion
                            </h3>
                            <div className='bg-white rounded-pill shadow w-75'>
                                <div className='input-group'>
                                    <input type='text'
                                           className='shadow-none form-control rounded-pill border-0 bg-white me-1'/>
                                    <Boton titulo='Iniciar Demo'/>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 text-center'>
                            <img src={icoDocumentos} alt='Documentos' style={{maxWidth: iconosGrandes}}/>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className='container-fluid fondoVideo p-0'>
                <div className='container p-0'>
                    <React.Suspense>
                        {idioma === 'es' ? <VideoEspaniol/> : <VideoIngles/>}
                    </React.Suspense>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-5 text-center'>
                            <img src={icoUsuario} alt='Usuario' style={{maxWidth: iconosGrandes}}/>
                        </div>
                        <div className='col-lg-7 pt-5'>
                            <h3 className='mb-4'>
                                {idioma === 'es' ? 'Tu aliado para la gestión de proveedores' :
                                    'Your ally for supplier management'}
                            </h3>
                            <p className='mb-4'>
                                {idioma === 'es' ?
                                    'Bcontracts te ayuda a simplificar la gestión de la documentación de tus proveedores.\n' +
                                    'Somos una plataforma basada en datos, que te permite gestionar contratos,\n' +
                                    'autorizaciones y documentación legal de un modo simple, eficiente e intuitivo.' :
                                    'Bcontracts helps you to simplify the documentation management of your suppliers.\n' +
                                    'We are a platform based on data, which allows you to manage contracts,\n' +
                                    'authorizations and legal documentation in a simple, efficient and intuitive way.'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Más información' : 'More information'}
                                   onClick={() => navigate('/detalle?q=inicio')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-light'>
                <div className='container py-5'>
                    <h3 className='my-4 text-center'>
                        {idioma === 'es' ? 'Una solución para gestionar de modo más eficiente' : ''}
                    </h3>
                    <p className='text-center'>
                        {idioma === 'es' ?
                            'Las empresas pierden mucho tiempo (y recursos) en revisión y reclamo de documentación todos ' +
                            'los días; también incontables procesos deben ser pospuestos porque no se cuentan con las ' +
                            'aprobaciones en tiempo y forma. Podemos ayudar a simplificarlo a través de estos 4 sencillos ' +
                            'ejes.' :
                            'Companies waste a lot of time (and resources) reviewing and claiming documentation every ' +
                            'the days; also countless processes must be postponed because they do not have the ' +
                            'approvals in a timely manner. We can help simplify it through these 4 simple ' +
                            'axes.'}
                    </p>
                    <div className='row mt-5'>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoControl} style={{maxWidth: iconosChicos}} alt='Control'/>
                            <h5 className='my-4'>Control</h5>
                            <p className='small'>
                                {idioma === 'es' ?
                                    'Mantiene toda tu información en un único lugar, seguro, visible y con tableros de ' +
                                    'seguimiento' :
                                    'Keep all your information in one place, safe, visible and with dashboards ' +
                                    'tracing'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Más información' : 'More information'} outline
                                   onClick={() => navigate('/detalle?q=seguimiento')}/>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoPrevisibilidad} style={{maxWidth: iconosChicos}} alt='Previsibilidad'/>
                            <h5 className='my-4'>{idioma === 'es' ? 'Previsibilidad' : 'Predictability'}</h5>
                            <p className='small'>
                                {idioma === 'es' ?
                                    'Programá qué documentación te deben enviar tus proveedores y automatizá ' +
                                    'responsables, vencimientos y avisos' :
                                    'Program what documentation your suppliers should send you and automate ' +
                                    'responsible, due dates and notices'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Más información' : 'More information'} outline
                                   onClick={() => navigate('/detalle?q=seguimiento')}/>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoEficiencia} style={{maxWidth: iconosChicos}} alt='Eficiencia'/>
                            <h5 className='my-4'>{idioma === 'es' ? 'Eficiencia' : 'Efficiency'}</h5>
                            <p className='small'>
                                {idioma === 'es' ?
                                    'Identifica rápidamente desvíos. Reduce tiempos y optimiza tu productividad' :
                                    'Quickly identify deviations. Reduce time and optimize your productivity'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Más información' : 'More information'} outline
                                   onClick={() => navigate('/detalle?q=seguimiento')}/>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoSustentabilidad} style={{maxWidth: iconosChicos}} alt='Sustentabilidad'/>
                            <h5 className='my-4'>{idioma === 'es' ? 'Sustentabilidad' : 'Sustainability'}</h5>
                            <p className='small'>
                                {idioma === 'es' ?
                                    'Reduce tu consumo de papel y junto a nosotros apoya causas por el medio ' +
                                    'ambiente' :
                                    'Reduce your paper consumption and together with us support causes for the ' +
                                    'environment'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Más información' : 'More information'} outline
                                   onClick={() => navigate('/detalle?q=seguimiento')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <div className='row mt-5 mb-4'>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoNegocio} style={{maxWidth: iconosMini}} alt='Negocio'/>
                            <h5 className='my-4'>
                                {idioma === 'es' ?
                                    'Disponible en el mercado desde 2017' :
                                    'Available on the market since 2017'}
                            </h5>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoSistema} style={{maxWidth: iconosMini}} alt='Sistema'/>
                            <h5 className='my-4'>
                                {idioma === 'es' ?
                                    `Más de ${fmt.format(horas)} horas de uso` :
                                    `More than ${fmt.format(horas)} hours of use`}
                            </h5>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoUsuarios} style={{maxWidth: iconosMini}} alt='Usuarios'/>
                            <h5 className='my-4'>
                                {idioma === 'es' ?
                                    'Más de 500 usuarios ya lo utilizan' :
                                    'More than 500 users already use it'}
                            </h5>
                        </div>
                        <div className='col-lg-3 text-center px-5'>
                            <img src={icoArbol} style={{maxWidth: iconosMini}} alt='Arbol'/>
                            <h5 className='my-4'>
                                {idioma === 'es' ?
                                    'Más de 600.000 documentos digitales' :
                                    'More than 600,000 digital documents'}</h5>
                            <p className='small'>
                                {idioma === 'es' ?
                                    'Más de 185 árboles que se evitan usar para fabricar papel' :
                                    'More than 185 trees that are avoided to be used to make paper'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {idioma === 'es' ? (
                <div className='container-fluid bg-info bg-opacity-25' ref={planesRef}>
                    <div className='container py-5'>
                        <h3 className='my-4 text-center mb-5'>{idioma === 'es' ? 'Planes' : 'Plans'}</h3>
                        <div className='row w-75 mx-auto mb-4'>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Standard' precio={idioma === 'es' ? 'Contáctenos' : 'Contact Us'}
                                               caracteristicas={idioma === 'es' ? [
                                    'Hasta 100 legajos (contratistas, empleados, vehículos, obras y contratos)'
                                ] : [
                                    'Up to 100 files (contractors, employees, vehicles, works and contracts)'
                                ]}
                                               tituloBoton={idioma === 'es' ? 'Comenzar' : 'Start'}
                                               enlaceBoton={enlaceMail}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Advanced' precio={idioma === 'es' ? 'Contáctenos' : 'Contact Us'}
                                               caracteristicas={idioma === 'es' ? [
                                    'De 101 a 300 legajos (contratistas, empleados, vehículos, obras y contratos)'
                                ] : [
                                    'From 101 to 300 files (contractors, employees, vehicles, works and contracts)'
                                ]}
                                               tituloBoton={idioma === 'es' ? 'Comenzar' : 'Start'}
                                               enlaceBoton={enlaceMail}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Enterprise'
                                               precio={idioma === 'es' ? 'Contáctenos' : 'Contact Us'}
                                               azul
                                               caracteristicas={idioma === 'es' ? [
                                                   'Obtené un plan a tu medida'
                                               ] : [
                                                   'Get a plan tailored to you'
                                               ]}
                                               tituloBoton={idioma === 'es' ? 'Comenzar' : 'Start'}
                                               enlaceBoton={enlaceMail}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container-fluid bg-info bg-opacity-25' ref={planesRef}>
                    <div className='container py-5'>
                        <h3 className='my-4 text-center mb-5'>
                            We are always looking for business opportunities
                        </h3>
                        <div className='row w-75 mx-auto mb-4'>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Future Users'
                                               caracteristicas={[
                                                   'Save time',
                                                   'Reduce Stress',
                                                   'Decrease Costs',
                                                   'Improve Cash Flow'
                                               ]}
                                               tituloBoton='Learn more'
                                               enlaceBoton='futureusers'
                                />
                            </div>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Future Retailer'
                                               subtitulo='(Requires a small investment)'
                                               caracteristicas={[
                                                   'Keep your Independence, set\n' +
                                                   'your own schedule and\n' +
                                                   'financial goals',
                                                   'Diversify your sources of\n' +
                                                   'income',
                                                   'Earn commissions and\n' +
                                                   'bonuses',
                                                   'Build your reputation as a\n' +
                                                   'solution provider to your\n' +
                                                   'current clients and attract\n' +
                                                   'new ones'
                                               ]}
                                               tituloBoton='Learn more'
                                               enlaceBoton='futureretailer'
                                />
                            </div>
                            <div className='col-lg-4'>
                                <TarjetaPlanes titulo='Future Partner'
                                               subtitulo='(Requires a sizeable investment)'
                                               caracteristicas={[
                                                   'Increase your plethora of\n' +
                                                   'services with an application\n' +
                                                   'that eliminates bottlenecks\n' +
                                                   'and improves the collection\n' +
                                                   'of accounts receivables',
                                                   'Consider offering Online\n' +
                                                   'Education to your current\n' +
                                                   'clients on how to deterr\n' +
                                                   'Cybercrime',
                                                   'Spearhead growth and cost\n' +
                                                   'containtment to your\n' +
                                                   'operation and your clients',
                                                   'Expand your market share\n' +
                                                   'and industries outreach'
                                               ]}
                                               tituloBoton='Learn more'
                                               enlaceBoton='futurepartner'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='container-fluid'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 pt-5'>
                            <h3>{idioma === 'es' ?
                                'Organizá ya tu gestión documental' :
                                'Organize your document management'}
                            </h3>
                            <p>{idioma === 'es' ? 'Contactanos' : 'Contact Us'}</p>
                            <Boton titulo='hello@bcontracts.com' onClick={() => window.location.href = enlaceMail}/>
                        </div>
                        <div className='col-lg-5 text-center'>
                            <img src={icoDocumentos} alt='Documentos' style={{maxWidth: iconosGrandes}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Inicio