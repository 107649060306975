import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Inicio from "./Inicio";
import Detalle from "./Detalle";
import Nosotros from "./Nosotros";
import {Provider} from "react-redux";
import store from "./store";
import Novedades from "./Novedades";
import DetalleFutureUsers from "./DetalleFutureUsers";
import DetalleFutureRetailer from "./DetalleFutureRetailer";
import DetalleFuturePartner from "./DetalleFuturePartner";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<App/>}>
                        <Route index element={<Inicio/>}/>
                        <Route path='details' element={<Detalle/>}/>
                        <Route path='aboutus' element={<Nosotros/>}/>
                        <Route path='whatsnew' element={<Novedades/>}/>
                        <Route path='futureusers' element={<DetalleFutureUsers/>}/>
                        <Route path='futureretailer' element={<DetalleFutureRetailer/>}/>
                        <Route path='futurepartner' element={<DetalleFuturePartner/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
