import {useAppSelector} from "./hooks";
import {useEffect, useRef} from "react";

const Novedades = () => {
    const idioma = useAppSelector(state => state.idioma.idioma)
    const inicioRef = useRef()

    useEffect(() => {
        inicioRef.current?.scrollIntoView()
    })

    const checkLi = <span className='fa-li'><i className='fas fa-check-circle text-primary'/></span>
    const checkLn = <span className='fa-li'><i className='fas fa-caret-right small text-secondary'/></span>

    return (
        <>
            <div className='container-fluid fondoGradientInicio py-5' ref={inicioRef}>
                <div className='container'>
                    <h3 className='text-light text-center mb-0'>
                        {idioma === 'es' ? 'Novedades' : 'What\'s New'}
                    </h3>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <h3>Marzo de 2023</h3>
                    <ul className='fa-ul'>
                        <li>
                            {checkLi}<h5>Mejoras</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Actualización de la plataforma técnica que lo soporta, haciéndolo más estable y
                                    seguro
                                </li>
                                <li>
                                    {checkLn}
                                    En la pantalla inicial luego del login, incorporación del logo de la empresa
                                    usuaria de la plataforma y, además, para el caso del rol de Administración,
                                    visualización de la consulta del panel de control
                                </li>
                                <li>
                                    {checkLn}
                                    Incorporación en el login de la función de recuperación de contraseña</li>
                                <li>
                                    {checkLn}
                                    Mejoras en la estética de la presentación de la plataforma</li>
                                <li>
                                    {checkLn}
                                    Ayuda en línea siempre disponible</li>
                                <li>
                                    {checkLn}
                                    Link de acceso a la página web de la plataforma</li>
                                <li>
                                    {checkLn}
                                    Link de acceso a las novedades de la plataforma a través de la página web</li>
                                <li>
                                    {checkLn}
                                    En el ambiente de TESTING, siempre visible la leyenda indicando de que se está
                                    en el ambiente de TESTING y no en el de PRODUCCIÓN
                                    La forma de operar la plataforma no cambia en absoluto para el usuario.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>
                    <h3>Noviembre de 2022</h3>
                    <ul className='fa-ul'>
                        <li>
                            {checkLi}<h5>Nuevas Funcionalidades</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Incorporación del tablero inicial de gestión para todos los usuarios</li>
                                <li>
                                    {checkLn}
                                    Nuevo nivel de requisitos por contrato y su administración con gestores,
                                    supervisores y horas.
                                </li>
                            </ul>
                        </li>
                        <li>
                            {checkLi}<h5>Mejoras</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Pantalla de ingreso o login (cambio del “look and feel”, links a la página web
                                    del B-Contracts y de Binary, acceso a información de contacto, acceso a
                                    información de novedades de la solución)
                                </li>
                                <li>
                                    {checkLn}
                                    Pantalla principal (cambio del “look and feel”, acceso al Manual de Uso en
                                    línea, visualización del tablero inicial de gestión para todos los usuarios, links
                                    a la página web del B-Contracts y de Binary, acceso a información de contacto,
                                    acceso a información de novedades de la solución, logo del cliente).
                                </li>
                            </ul>
                        </li>
                        <li>
                            {checkLi}<h5>Otros</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Nueva versión de la página web del B-Contracts.</li>
                                <li>
                                    {checkLn}
                                    Actualización de versión del “software” de base que soporta a la solución.</li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>
                    <h3>Agosto 2022</h3>
                    <ul className='fa-ul'>
                        <li>
                            {checkLi}<h5>Nuevas Funcionalidades</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Nuevo nivel de requisitos por contrato y su administración con gestores,
                                    supervisores y horas.
                                </li>
                                <li>
                                    {checkLn}
                                    Posibilidad de acceso a los requisitos por sector de la compañía.</li>
                                <li>
                                    {checkLn}
                                    Envío de emails automáticos al momento de carga de determinados requisitos.</li>
                                <li>
                                    {checkLn}
                                    Acceso de usuarios por obra.</li>
                                <li>
                                    {checkLn}
                                    Inhabilitación masiva de empleados de un subcontratista a través de una
                                    planilla de cálculo.
                                </li>
                                <li>
                                    {checkLn}
                                    Inhabilitación de empresas, empleados, contratos y vehículos separada de su
                                    actualización general.
                                </li>
                                <li>
                                    {checkLn}
                                    Incorporación de requisitos por empresa por período (mensual o anual).</li>
                                <li>
                                    {checkLn}
                                    Facilidad de carga masiva de empleados y vehículos a través de una planilla de
                                    cálculo.
                                </li>
                            </ul>
                        </li>
                        <li>
                            {checkLi}<h5>Mejoras</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Pantallas con mayor agilidad en la operación.</li>
                                <li>
                                    {checkLn}
                                    Optimización de botones, títulos y literales de pantallas.</li>
                                <li>
                                    {checkLn}
                                    Mejoras en el motor de requisitos</li>
                                <li>
                                    {checkLn}
                                    Mejoras en las alertas</li>
                                <li>
                                    {checkLn}
                                    Mejoras en la empresas</li>
                                <li>
                                    {checkLn}
                                    Mejoras en los empleados</li>
                                <li>
                                    {checkLn}
                                    Mejoras en los requisitos</li>
                            </ul>
                        </li>
                        <li>
                            {checkLi}<h5>Procesos e Informes</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Nuevas consultas (por DNI / dominio, panel general, historial de novedades
                                    por CUIL, consolidado de requisitos)
                                </li>
                                <li>
                                    {checkLn}
                                    Mejoras a consultas existentes</li>
                            </ul>
                        </li>
                        <li>
                            {checkLi}<h5>Otros</h5>
                            <ul className='fa-ul'>
                                <li>
                                    {checkLn}
                                    Optimización del almacenamiento y recuperación de archivos de soporte de
                                    requisitos.
                                </li>
                                <li>
                                    {checkLn}
                                    Mejora en la seguridad e integridad de la plataforma tecnológica.</li>
                                <li>
                                    {checkLn}
                                    Optimización de los procesos de resguardo y recuperación de datos.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Novedades