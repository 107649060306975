import LogoBcontracts from "./LogoBcontracts";
import Boton from "./Boton";
import {useNavigate} from "react-router-dom";
import icoArg from './img/ico-argentina.png'
import icoUsa from './img/ico-usa.png'
import {useAppDispatch, useAppSelector} from "./hooks";
import {setIdioma} from "./idiomaReducer";

const HeaderBcontracts = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const idioma = useAppSelector(state => state.idioma.idioma)

    const estiloEnlaces = 'btn btn-link text-dark text-decoration-none'
    const estiloIconos = 'btn btn-link px-1'
    const enlaceMail = 'mailto:hello@bcontracts.com?subject=' +
        encodeURIComponent('Quiero más información sobre bcontracts')
    const enlaceApp = 'https://app.bcontracts.com'

    return (
        <div>
            <div className='container'>
                <div className='row my-4'>
                    <div className='col-auto'>
                        <LogoBcontracts/>
                    </div>
                    <div className='col-auto'>
                        <button type='button' className={estiloEnlaces}
                                onClick={() => navigate('/details')}>
                            {idioma === 'es' ? 'Por qué Bcontracts?' : 'Why Bcontracts?'}
                        </button>
                        <button type='button' className={estiloEnlaces}
                                onClick={() => navigate('/?q=plans')}>
                            {idioma === 'es' ? 'Planes' : 'Plans'}
                        </button>
                        <button type='button' className={estiloEnlaces}
                                onClick={() => navigate('/aboutus')}>
                            {idioma === 'es' ? 'Nosotros' : 'About Us'}
                        </button>
                        <button type='button' className={estiloEnlaces}
                                onClick={() => navigate('/whatsnew')}>
                            {idioma === 'es'? 'Novedades' : 'What\'s New'}
                        </button>
                    </div>
                    <div className='col text-end'>
                        <Boton titulo={idioma === 'es' ? 'Iniciar sesión' : 'Sign In'} outline margen
                               onClick={() => window.location.href = enlaceApp}/>
                        <Boton titulo={idioma === 'es' ? 'Contactanos' : 'Contact Us'}
                               onClick={() => window.location.href = enlaceMail}/>
                    </div>
                    <div className='col-auto'>
                        <button type='button' className={estiloIconos}
                                style={{
                                    opacity: idioma === 'es' ? 1 : 0.5,
                                    filter: idioma === 'es' ? undefined : 'grayscale()'
                                }}
                                onClick={() => dispatch(setIdioma('es'))}>
                            <img src={icoArg} alt='Español'/>
                        </button>
                        <button type='button' className={estiloIconos}
                                style={{
                                    opacity: idioma === 'en' ? 1 : 0.5,
                                    filter: idioma === 'en' ? undefined : 'grayscale()'
                                }}
                                onClick={() => dispatch(setIdioma('en'))}>
                            <img src={icoUsa} alt='English'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderBcontracts