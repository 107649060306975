const Boton = ({titulo, onClick, outline, margen, chico, blanco}) => {
    const estiloNormal = 'btn btn-primary rounded-pill px-4'
    const estiloBlanco = 'btn btn-light rounded-pill px-4'
    const estiloOutline = 'btn btn-outline-primary rounded-pill px-4'

    return (
        <button type='button' onClick={onClick}
                className={(outline ? estiloOutline : blanco ? estiloBlanco : estiloNormal) +
                    (margen ? ' me-3' : '') + (chico ? ' btn-sm' : '')}>
            {titulo}
        </button>
    )
}

export default Boton