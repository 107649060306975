import {useState} from "react";
import Boton from "./Boton";
import {useNavigate} from "react-router-dom";

const DetalleFutureUsers = () => {
    const [datos, setDatos] = useState({
        name: '', lastname: '', organization: '', position: '', email: '', phone: '', employees: '0',
        industry: '', yearsinbusiness: '0', timetocontact: ''
    })
    const [paso, setPaso] = useState(0)
    const navigate = useNavigate()

    return (
        <>
            <div className='container-fluid fondoGradientInicio py-5'>
                <div className='container'>
                    <h3 className='text-light text-center mb-0'>
                        Learn More
                    </h3>
                </div>
            </div>
            {paso === 0 ? (
                <div className='container-fluid'>
                    <div className='container py-5'>
                        <h2>Future Users</h2>
                        <h4>Required Information</h4>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Name</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.name}
                                       onChange={e => setDatos({...datos, name: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Last Name</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.lastname}
                                       onChange={e => setDatos({...datos, lastname: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Organization</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.organization}
                                       onChange={e => setDatos({...datos, organization: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Position</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.position}
                                       onChange={e => setDatos({...datos, position: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>E-Mail</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.email}
                                       onChange={e => setDatos({...datos, email: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Phone</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.phone}
                                       onChange={e => setDatos({...datos, phone: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Number of Employees</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.employees}
                                       onChange={e => setDatos({...datos, employees:
                                               (parseInt(e.target.value.replace(/\D/, '')) || 0).toString()})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Industry</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.industry}
                                       onChange={e => setDatos({...datos, industry: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Years in Business</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.yearsinbusiness}
                                       onChange={e => setDatos({...datos, yearsinbusiness:
                                               (parseInt(e.target.value.replace(/\D/, '')) || 0).toString()})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Best Time to Contact</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.timetocontact}
                                       onChange={e => setDatos({...datos, timetocontact: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-8 offset-md-4'>
                                <Boton titulo='Continue' margen onClick={() => setPaso(1)}/>
                                <Boton titulo='Go Back' blanco onClick={() => navigate('../')}/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container-fluid'>
                    <div className='container py-5'>
                        <h2>Future Users</h2>
                        <h4>Tariffs</h4>
                        <div className='row mb-3'>
                            <div className='col-md-4'>One License Installation Fee</div>
                            <div className='col-md-8'>$1,500</div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4'>Files</div>
                            <div className='col-md-8'>
                                40
                                <br/>
                                <small>
                                    (1 file stores the documentation of one person,
                                    one piece of equipment, or one client or vendor)
                                </small>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4'>Monthly Maintenance Fee</div>
                            <div className='col-md-8'>$1,000</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-8 offset-md-4'>
                                <Boton titulo='Submit' margen/>
                                <Boton titulo='Go Back' blanco onClick={() => setPaso(0)}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DetalleFutureUsers