import logoBinary from './img/binary-logo.png'
import {useAppSelector} from "./hooks";

const Nosotros = () => {
    const idioma = useAppSelector(state => state.idioma.idioma)

    return (
        <>
            <div className='container-fluid fondoGradientInicio py-5'>
                <div className='container'>
                    <h3 className='text-light text-center mb-0'>
                        {idioma === 'es' ? 'Nosotros' : 'About Us'}
                    </h3>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <h3 className='mb-4'>
                        <img src={logoBinary} alt='Binary' style={{cursor: 'pointer'}}
                             onClick={() => window.open('https://www.binaryarg.com', '_blank')}/>
                    </h3>
                    <p className='mb-5'>
                        {idioma === 'es' ?
                            'Somos una empresa proveedora de insumos y soluciones sustentables para la industria ' +
                            'en general.' :
                            'We are a supplier of supplies and sustainable solutions for the industry in general.'}
                    </p>
                    <p className='mb-5'>
                        {idioma === 'es' ?
                            'A lo largo de nuestra trayectoria, hemos logrado un alto nivel de calidad en todos ' +
                            'nuestros productos y servicios, buscando siempre innovación y sustentabilidad. Con ' +
                            'estructuras y procesos flexibles nos adaptarnos a la demanda del mercado y a las ' +
                            'especificaciones de nuestros clientes.' :
                            'Throughout our history, we have achieved a high level of quality in all our products ' +
                            'and services, always seeking innovation and sustainability. With flexible structures ' +
                            'and processes, we adapt to market demand and our clients\' specifications.'}
                    </p>
                    <p className='mb-5'>
                        {idioma === 'es' ?
                            'A través de diversas alianzas estratégicas, tenemos presencia en Estados Unidos, ' +
                            'Latinoamérica y Europa, lo que nos permite una rápida, ágil y eficiente logística ' +
                            'a toda la región.' :
                            'Through various strategic alliances, we have a presence in the United States, Latin ' +
                            'America and Europe, which allows us fast, agile and efficient logistics throughout ' +
                            'the region.'}
                    </p>
                </div>
            </div>
        </>
    )
}

export default Nosotros