import logo from './img/bcontracts-logo.png'
import {useNavigate} from "react-router-dom";

const LogoBcontracts = () => {
    const navigate = useNavigate()

    return (
        <div style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
            <img src={logo} alt='Bcontracts' style={{maxWidth: '200px'}}/>
        </div>
    )
}

export default LogoBcontracts