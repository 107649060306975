const ListaChecks = ({items}) => {
    return items.map((i, k) => (
        <div className='row mb-3 small g-2' key={k}>
            <div className='col-auto'>
                <i className='fas fa-check-circle text-primary'/>
            </div>
            <div className='col'>{i}</div>
        </div>
    ))
}

export default ListaChecks