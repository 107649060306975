import icoControl from './img/ico-control.png'
import icoPrevisibilidad from './img/ico-previsibilidad.png'
import icoEficiencia from './img/ico-eficiencia.png'
import icoSustentabilidad from './img/ico-sustentabilidad.png'
import Boton from "./Boton";
import {useAppSelector} from "./hooks";
import {useEffect, useRef} from "react";
import {useSearchParams} from "react-router-dom";

const Detalle = () => {
    const iconosMedianos = '14vw'
    const idioma = useAppSelector(state => state.idioma.idioma)
    const [params] = useSearchParams()

    const enlaceMail = 'mailto:hello@bcontracts.com?subject=' +
        encodeURIComponent('Quiero más información sobre bcontracts')

    const inicioRef = useRef()
    const seguimientoRef = useRef()

    useEffect(() => {
        switch (params.get('q')) {
            case 'inicio':
                inicioRef.current?.scrollIntoView()
                break;
            case 'seguimiento':
                seguimientoRef.current?.scrollIntoView()
                break;
            default:
        }
    }, [params])

    return (
        <>
            <div className='container-fluid fondoGradientInicio py-5' ref={inicioRef}>
                <div className='container'>
                    <h3 className='text-light text-center mb-0'>
                        {idioma === 'es' ? 'Por qué Bcontracts?' : 'Why Bcontracts?'}
                    </h3>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-5 pt-5 text-center'>
                            <img src={icoControl} alt='Control' style={{maxWidth: iconosMedianos}}/>
                        </div>
                        <div className='col-lg-7 pt-5'>
                            {/*
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Información 100% trazable y segura bajo tecnología blockchain' :
                                    '100% traceable and secure information under blockchain technology'}
                            </h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Podés revisar tus registros históricos en un único lugar, con altas barreras ' +
                                    'de seguridad. Mantiene toda tu información en un único lugar, visible y con ' +
                                    'tableros de seguimiento.' :
                                    'You can review your historical records in one place, with high security ' +
                                    'barriers. Keep all your information in one place, visible and with ' +
                                    'tracking boards.'}
                            </p>
                            */}
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Tableros versátiles para análisis y generación de reportes' :
                                    'Versatile dashboards for analysis and reporting'}
                            </h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Identifica y crea reportes contemplando aquellos recursos y procesos que ' +
                                    'necesitas como responsables, vencimientos y pendientes con una interfase ' +
                                    'amigable, fácil, intuitiva, multii-dioma y autogestionable' :
                                    'Identify and create reports contemplating those resources and processes that ' +
                                    'you need as responsible, maturities and pending with a friendly, easy, ' +
                                    'intuitive, multi-language and self-manageable interface'}
                            </p>
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Integrable con los sistemas de gestión centralizada presentes en el mercado' :
                                    'Integrable with centralized management systems on the market'}
                            </h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Software Cloud-based 24x7 con gestión vía web browser, open source y con ' +
                                    'actualización en tiempo real.' :
                                    'Cloud-based 24x7 software with management via web browser, open source and ' +
                                    'real-time updating.'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Contactanos' : 'Contact Us'}
                                   onClick={() => window.location.href = enlaceMail}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-light' ref={seguimientoRef}>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 pt-5'>
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Haz que nuestro software haga el seguimiento por ti' :
                                    'Let our software do the tracking for you'}
                            </h3>
                            <p className='mb-4'>
                                {idioma === 'es' ?
                                    'Automatiza las notificaciones, la validación de carga de documentos, la ' +
                                    'revisión de faltantes y vencimientos.' :
                                    'Automate notifications, document upload validation, review of missing items ' +
                                    'and expirations.'}
                            </p>
                            <div className='row mb-5'>
                                <div className='col-md-3 text-center'>
                                    <div>
                                        <i className='fas fa-check-circle text-primary mb-3'
                                           style={{fontSize: '2em'}}/>
                                    </div>
                                    {idioma === 'es' ?
                                        'Carga de Documentos' :
                                        'Document Upload'}
                                </div>
                                <div className='col-md-3 text-center'>
                                    <div>
                                        <i className='fas fa-check-circle text-primary mb-3'
                                           style={{fontSize: '2em'}}/>
                                    </div>
                                    {idioma === 'es' ?
                                        'Reclamo de Faltantes' :
                                        'Missing claim'}
                                </div>
                                <div className='col-md-3 text-center'>
                                    <div>
                                        <i className='fas fa-check-circle text-primary mb-3'
                                           style={{fontSize: '2em'}}/>
                                    </div>
                                    {idioma === 'es' ?
                                        'Avisos de Vencimiento' :
                                        'Expiration Notices'}
                                </div>
                                <div className='col-md-3 text-center'>
                                    <div>
                                        <i className='fas fa-check-circle text-primary mb-3'
                                           style={{fontSize: '2em'}}/>
                                    </div>
                                    {idioma === 'es' ?
                                        'Nivel de Cumplimiento' :
                                        'Level of Compliance'}
                                </div>
                            </div>
                            {/*
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Prioriza los procesos más importantes' :
                                    'Prioritize the most important processes'}
                            </h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Genera alertas inteligentes con nuestro motor de machine learning.' :
                                    'Generate smart alerts with our machine learning engine.'}
                            </p>
                            */}
                            <Boton titulo={idioma === 'es' ? 'Contactanos' : 'Contact Us'}
                                   onClick={() => window.location.href = enlaceMail}/>
                        </div>
                        <div className='col-lg-5 pt-5 text-center'>
                            <img src={icoPrevisibilidad} alt='Previsibilidad' style={{maxWidth: iconosMedianos}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-5 pt-5 text-center'>
                            <img src={icoEficiencia} alt='Eficiencia' style={{maxWidth: iconosMedianos}}/>
                        </div>
                        <div className='col-lg-7 pt-5'>
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Construye un ecosistema comprometido y auto-gestionable' :
                                    'Build a committed and self-managed ecosystem'}
                            </h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Empodera a tus usuarios y genera ahorros a través de la aceleración y ' +
                                    'reducción de tiempos de cada proceso' :
                                    'Empower your users and generate savings by accelerating and reducing the time ' +
                                    'of each process'}
                            </p>
                            <h3 className='mb-4'>{idioma === 'es' ?
                                'La velocidad es todo' :
                                'Speed is everything'}</h3>
                            <p className='mb-5'>
                                {idioma === 'es' ?
                                    'Los días son muy cortos y una demora puede tener un alto impacto. Dispara ' +
                                    'acciones y reclamos con nuestros atajos inteligentes, en un toque y sin ' +
                                    'necesidad de generar requerimientos adicionales.' :
                                    'The days are very short and a delay can have a high impact. Trigger actions ' +
                                    'and claims with our smart shortcuts, in one touch and without the need to ' +
                                    'generate additional requirements.'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Contactanos' : 'Contact Us'}
                                   onClick={() => window.location.href = enlaceMail}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-light'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 pt-5'>
                            <h3 className='mb-4'>
                                {idioma === 'es' ?
                                    'Organizá ya tu gestión documental' :
                                    'Organize your document management'}
                            </h3>
                            <p className='mb-4'>
                                {idioma === 'es' ?
                                    'En representación de nuestros clientes, apoyamos a ONGs comprometidos con la ' +
                                    'reforestación de la Patagonia Argentina.' :
                                    'On behalf of our clients, we support NGOs committed to the reforestation of ' +
                                    'Argentine Patagonia.'}
                            </p>
                            <p className='mb-4'>
                                {idioma === 'es' ?
                                    'Emitimos a nuestros clientes certificados de carbono captado de la atmósfera.' :
                                    'We issue our clients certificates of carbon captured from the atmosphere.'}
                            </p>
                            <Boton titulo={idioma === 'es' ? 'Contactanos' : 'Contact Us'}
                                   onClick={() => window.location.href = enlaceMail}/>
                        </div>
                        <div className='col-lg-5 pt-5 text-center'>
                            <img src={icoSustentabilidad} alt='Sustentabilidad' style={{maxWidth: iconosMedianos}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Detalle