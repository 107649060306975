const FooterBcontracts = () => {
    return (
        <div className='container-fluid bg-black text-light'>
            <div className='container py-5'>
                (C) 2023, BINARY
            </div>
        </div>
    )
}

export default FooterBcontracts