import Boton from "./Boton";
import {useAppSelector} from "./hooks";
import {useNavigate} from "react-router-dom";

const TarjetaPlanes = ({titulo, precio, azul, caracteristicas, tituloBoton, enlaceBoton, impuestos, subtitulo}) => {
    const fondo = azul ? 'bg-primary text-light' : 'bg-white'
    const idioma = useAppSelector(state => state.idioma.idioma)
    const navigate = useNavigate()

    return (
        <div className={fondo + ' rounded-5 shadow px-4 py-4 mx-3 h-100 d-flex flex-column'}>
            <div style={{height: '5rem'}}>
                <h5>{titulo}</h5>
                {subtitulo !== undefined && (<h6>{subtitulo}</h6>)}
            </div>
            <div className='mb-3'>
                <h4 className='mb-0'>{precio}</h4>
                {impuestos && (
                    <div style={{fontSize: '0.75em'}}>
                        {idioma === 'es' ? 'por mes + Impuestos' : 'monthly + Taxes'}
                    </div>
                )}
            </div>
            <div className='flex-grow-1'>
                {caracteristicas?.map((c, k) => (
                    <div className='row mb-3 small g-2' key={k}>
                        <div className='col-auto'>
                            <i className={'fas fa-check-circle ' + (azul ? 'text-light' : 'text-primary')}/>
                        </div>
                        <div className='col'>{c}</div>
                    </div>
                ))}
            </div>
            <div className='mt-4 text-center'>
                <Boton titulo={tituloBoton} chico blanco={azul}
                       onClick={() => {
                           if (enlaceBoton.startsWith("mailto:")) {
                               window.location.href = enlaceBoton
                           } else {
                               navigate(enlaceBoton)
                           }
                       }}/>
            </div>
        </div>
    )
}

export default TarjetaPlanes