import {createSlice} from "@reduxjs/toolkit";

const idiomaSlice = createSlice({
    name: 'idioma',
    initialState: {
        idioma: navigator.language.substring(0, 2) === 'es' ? 'es' : 'en'
    },
    reducers: {
        setIdioma: (state, action) => {
            state.idioma = action.payload
        }
    }
})

export const {setIdioma} = idiomaSlice.actions

export default idiomaSlice.reducer