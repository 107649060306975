import './App.scss';
import HeaderBcontracts from "./HeaderBcontracts";
import {Outlet} from "react-router-dom";
import FooterBcontracts from "./FooterBcontracts";

function App() {
    return (
        <div className="App">
            <HeaderBcontracts/>
            <Outlet/>
            <FooterBcontracts/>
        </div>
    );
}

export default App;
