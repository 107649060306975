import {useState} from "react";
import Boton from "./Boton";
import {useNavigate} from "react-router-dom";
import ListaChecks from "./ListaChecks";

const DetalleFutureRetailer = () => {
    const [datos, setDatos] = useState({
        name: '', lastname: '', organization: '', address: '', city: '', state: '', country: '', phone: '',
        registered: false, type: '0', othertype: '', services: '', years: '0', withus: false, when: ''
    })
    const [paso, setPaso] = useState(0)
    const navigate = useNavigate()

    return (
        <>
            <div className='container-fluid fondoGradientInicio py-5'>
                <div className='container'>
                    <h3 className='text-light text-center mb-0'>
                        Learn More
                    </h3>
                </div>
            </div>
            {paso === 0 ? (
                <div className='container-fluid'>
                    <div className='container py-5'>
                        <h2>Future Retailer</h2>
                        <h4>Required Information</h4>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Name</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.name}
                                       onChange={e => setDatos({...datos, name: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Last Name</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.lastname}
                                       onChange={e => setDatos({...datos, lastname: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Organization</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.organization}
                                       onChange={e => setDatos({...datos, organization: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Address</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.address}
                                       onChange={e => setDatos({...datos, address: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>City</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.city}
                                       onChange={e => setDatos({...datos, city: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>State</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.state}
                                       onChange={e => setDatos({...datos, state: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Country</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.country}
                                       onChange={e => setDatos({...datos, country: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Phone</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.phone}
                                       onChange={e => setDatos({...datos, phone: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Is your Organization registered to do business?</div>
                            <div className='col-md-8'>
                                <select className='form-select' value={datos.registered? '1': '0'}
                                        onChange={e => setDatos({
                                            ...datos,
                                            registered: parseInt(e.target.value) === 1
                                        })}>
                                    <option value='0'>No</option>
                                    <option value='1'>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Type of Organization</div>
                            <div className='col-md-8'>
                                <select className='form-select' value={datos.type}
                                        onChange={e => setDatos({
                                            ...datos,
                                            type: e.target.value
                                        })}>
                                    <option value='0'>C</option>
                                    <option value='1'>S</option>
                                    <option value='2'>LLC</option>
                                    <option value='3'>Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Other</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.othertype}
                                       disabled={datos.type !== '3'}
                                       onChange={e => setDatos({...datos, othertype: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Services you are currently providing to your clients</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.services}
                                       onChange={e => setDatos({...datos, services: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Number of years in business</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.years}
                                       onChange={e => setDatos({...datos, years:
                                           (parseInt(e.target.value.replace(/\D/, '')) || 0).toString()})}/>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>Have you done business with us before</div>
                            <div className='col-md-8'>
                                <select className='form-select' value={datos.withus ? '1' : '0'}
                                        onChange={e => setDatos({
                                            ...datos,
                                            withus: parseInt(e.target.value) === 1
                                        })}>
                                    <option value='0'>No</option>
                                    <option value='1'>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4 pt-1'>If Yes, When?</div>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' value={datos.when}
                                       disabled={!datos.withus}
                                       onChange={e => setDatos({...datos, when: e.target.value})}/>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-md-8 offset-md-4'>
                                <Boton titulo='Continue' margen onClick={() => setPaso(1)}/>
                                <Boton titulo='Go Back' blanco onClick={() => navigate('../')}/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container-fluid'>
                    <div className='container py-5'>
                        <h2>Future Retailer</h2>
                        <ListaChecks items={[
                            'Earn 10% Commission on all sales',
                            'Earn $5,000 Bonus for every $250,000 in generated sales'
                        ]}/>
                        <h4>Requirements</h4>
                        <ListaChecks items={[
                            'Minimum 3 years in business',
                            'New Service Providers must be\n' +
                            'independent and have at least 3\n' +
                            'years proven experience in IT',
                            'Sales or IT Customer Service\n' +
                            'Suport',
                            'Must obtain and submit proof of\n' +
                            'business license'
                        ]}/>
                        <h4>Tariffs</h4>
                        <div className='row mb-3'>
                            <div className='col-md-4'>One License for 5 Clients Fee</div>
                            <div className='col-md-8'>$2,500</div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4'>Online Training Fee</div>
                            <div className='col-md-8'>$500</div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-4'>Annual Renewable Fee</div>
                            <div className='col-md-8'>$250</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-8 offset-md-4'>
                                <Boton titulo='Submit' margen/>
                                <Boton titulo='Go Back' blanco onClick={() => setPaso(0)}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DetalleFutureRetailer